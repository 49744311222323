<template>
  <div class="protokoll-firma">

    <h5 class="font-weight-bold" style="font-size: 1rem;">Firma / Frau / Herr*</h5>
    <CTextarea
      rows="5"
      disabled="disabled"
      v-model="kundeAusgewaehltText"
    >
      <template #append>
        <CButton color="light" v-on:click="openModal()">
          <CIcon name="cil-user-plus" />
        </CButton>
      </template>
    </CTextarea>

    <CModal
      title='Firma'
      color="success"
      size="lg"
      :show="firmenModal"
    >
      <div v-if="viewMode=='search'">
        <div class="button-container d-flex justify-content-end mb-2">
          <CButton @click="viewMode = 'new'" v-if="viewMode != 'new'" color="info" class="">Neu</CButton>
        </div>
        <KundenAuswahl
          ref="kundeninput"
          v-bind:validate="false"
          formLabelCLass="d-none"
          v-model="kunde"
        />
        <CInput disabled="disabled" v-model="kundenObjekt.name1" v-if="kundenObjekt"/>
        <CTextarea
          rows="5"
          disabled="disabled"
          v-model="kundenAdresse"
          v-if="kundenObjekt"
        />
      </div>

      <div v-if="viewMode=='new'">
        <NeuerKunde v-model="neuerKunde"/>
      </div>

      <template #header>
        <h6 class="modal-title">Firma</h6>
        <CButtonClose @click="firmenModal = false"/>
      </template>
      <template #footer>
          <div>
            <CButton @click="viewMode = 'search'" v-if="viewMode != 'search'" color="info" class="mr-2">Suche</CButton>
          </div>
          <div>
            <CButton @click="closeModal()" color="danger" class="mr-2">Abbrechen</CButton>
            <CButton v-if="viewMode == 'search'" v-on:click="kundeSelect" color="success">Übernehmen</CButton>
          </div>
      </template>
    </CModal>

  </div>
</template>

<script>
import KundenAuswahl from '@/components/KundenAuswahl'
import NeuerKunde from '@/components/NeuerKunde'

export default {
  name: 'protokoll-firma',
  props: {
  },
  components: {
    KundenAuswahl,
    NeuerKunde
  },
  computed: {
    kundenObjekt: function () {
      return this.kunde
    },
    kundenAdresse: function () {
      let result = ''
      const kundendetails = this.kundenObjekt
      if (kundendetails) {
        result = kundendetails.strasse + ' ' + kundendetails.hausnr + '\n'
        result = result + kundendetails.plz + ' ' + kundendetails.ort
      }
      return result
    }
  },
  data () {
    return {
      firmenModal: false,
      kunde: null,
      kundeAusgewaehlt: null,
      kundeAusgewaehltText: null,
      viewMode: 'search',
      neuerKunde: null
    }
  },
  watch: {
    neuerKunde: function () {
      if (this.neuerKunde) {
        this.$store.dispatch('kunden/REFRESH')
        this.kunde = this.neuerKunde
        this.$emit('input', this.kunde)
        this.closeModal()
      }
    },
    kunde: function () {
      this.$emit('input', this.kunde)
      this.kundenObjekt = this.kunde
      this.kundeAusgewaehltText = ''
    },
    viewMode: function () {
      if (this.viewMode === 'search') {
        this.$nextTick(function () {
          if (this.$refs.kundeninput) {
            this.$refs.kundeninput.$refs.kundeselect.$refs.search.focus()
          }
        })
      }
    }
  },
  methods: {
    kundenDetails: function () {
      let result = ''
      const kundendetails = this.kunde
      if (kundendetails) {
        result = kundendetails.name1
        if (kundendetails.name2) {
          result = result + ' / ' + kundendetails.name2 + '\n'
        } else {
          result = result + '\n'
        }
        if (kundendetails.zusatz) {
          result = result + kundendetails.zusatz + '\n'
        }
        result = result + '\n' + kundendetails.strasse + ' ' + kundendetails.hausnr + '\n'
        result = result + kundendetails.plz + ' ' + kundendetails.ort
      }
      this.kundeAusgewaehltText = result
      return result
    },
    openModal: function () {
      this.firmenModal = true

      this.$nextTick(function () {
        if (this.$refs.kundeninput) {
          this.$refs.kundeninput.$refs.kundeselect.$refs.search.focus()
        }
      })
    },
    closeModal: function () {
      this.firmenModal = false
      this.viewMode = 'search'
    },
    kundeSelect: function () {
      this.kundenDetails()
      this.kundeAusgewaehlt = this.kunde
      this.closeModal()
    }
  }
}
</script>
