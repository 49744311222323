<template>
  <CRow>
    <CCol sm="12">

      <CCard>
        <CCardHeader>
          Geleistete Stunden
        </CCardHeader>
        <CCardBody class="tagesansicht">

          <!-- {{tagesansicht}} -->

          <CRow v-for="(protokoll, index) in tagesansicht.protokolle" v-bind:key="'prt' + index" class="tkprotokollentry mt-3 pt-2 pb-2">
            <CCol sm="12" class="basisinfo">
              <CRow>
                <CCol sm="2">
                  <strong>Protokoll</strong>
                  <small class="d-block">
                    Nr. {{protokoll.protokollid}}
                  </small>
                  <small class="d-block" v-if="protokoll.rechnungsnummer">
                    Rg. {{protokoll.rechnungsnummer}}
                  </small>
                </CCol>

                <CCol sm="5">
                  <strong>Kunde:</strong>
                  <br/>
                  <span v-if="protokoll.kunde">
                    {{protokoll.kunde.name1}}
                    <span v-if="protokoll.projekt">
                      | {{protokoll.projekt.name}}
                    </span>
                  </span>
                  <span v-else>-</span>
                </CCol>

                <CCol sm="2">
                  <div v-if="protokoll.abgeschlossen">
                     <strong> nicht abgeschlossen</strong>
                  </div>
                </CCol>

                <CCol sm="1" class="text-center">
                  <CButton
                    v-if="protokoll.unterschriften && protokoll.unterschriften.length < 1"
                    color="danger"
                    size="sm"
                    v-on:click="print(protokoll.protokollid)"
                    v-c-tooltip="{content: 'Protokoll unterschreiben', placement:'left'}"
                  >
                    <CIcon name="cil-highlighter" size="xl"/>
                  </CButton>
                  <CButton
                    color="success"
                    size="sm"
                    v-on:click="print(protokoll.protokollid)"
                    v-c-tooltip="{content: 'Protokoll ausdrucken', placement:'left'}"
                  >
                    <CIcon name="cil-print" size="xl"/>
                  </CButton>
                </CCol>

                <CCol sm="2" class="text-right">
                  <span v-for="(arbeitszeit, indexa) in protokoll.arbeitszeiten" v-bind:key="'prt' + indexa" class="stunden">
                    <span v-if="user.userid == arbeitszeit.mitarbeiter.mitarbeiterid && getFormatedDate(datum) === getFormatedDate(arbeitszeit.datum) " class="font-weight-bold">
                      <span v-if="!protokoll.tageskalender" style="color: red; font-weight: bold;">
                          {{arbeitszeit.dauer|number('0.00')}} Std.
                       </span>
                       <span v-else>
                          {{arbeitszeit.dauer|number('0.00')}} Std.
                       </span>
                       <br/>
                    </span>
                  </span>
                </CCol>
              </CRow>
            </CCol>
          </CRow>

          <CRow v-for="(tkalender, index) in tagesansicht.tageskalender" v-bind:key="'tgk' + index" class="tkalendarentry mt-3 pt-2">
            <CCol sm="12" class="basisinfo">
              <CRow>
                <CCol sm="2" class="typ">Tageskalender</CCol>
                <CCol sm="7">
                  <strong>Kunde:</strong>
                  <br/>
                  <span v-if="tkalender.kunde">
                    {{tkalender.kunde.name1}}
                    <span v-if="tkalender.projekt">
                      | {{tkalender.projekt.name}}
                    </span>
                    <span class="arbeitsbeschreibung d-block">
                      {{tkalender.taetigkeit}}
                    </span>
                  </span>
                  <span v-else>-</span>
                </CCol>
                <CCol sm="1" class="text-center">
                  <CButtonGroup :vertical="true">
                    <CButton
                      color="success"
                      size="sm"
                      v-c-tooltip="{content: 'In Protokoll umwandeln', placement:'left'}"
                      v-on:click="tkCopy(tkalender.tageskalenderid)"
                    >
                      <CIcon name="cil-calendar" size="xl"/>
                    </CButton>
                    <CButton
                      color="danger"
                      size="sm"
                      v-if="trickVue.$roleBasedSecurity.userHasRole(['ROLE_TEAM', 'ROLE_REPORT', 'ROLE_ADMIN'])"
                      v-c-tooltip="{content: 'Eintrag aus Tageskalender Löschen', placement:'left'}"
                      v-on:click="tkDelete(tkalender.tageskalenderid)"
                    >
                      <CIcon name="cil-trash" size="xl"/>
                    </CButton>
                  </CButtonGroup>
                </CCol>
                <CCol sm="2" class="text-right font-weight-bold">
                  {{tkalender.dauer|number('0.00')}} Std.
                </CCol>
                <CCol sm="12" class="mt-2 zukuenftigberechnen text-right" v-if="tkalender.zukuenftigBerechnen">
                  <CIcon name="cil-library-add" size="xl"/>
                  Dieser Tageskalender-Eintrag wird dem Kunden zukünftig berechnet, und in ein Protokoll umgewandelt.
                </CCol>
              </CRow>
            </CCol>
            <CCol sm="12" v-if="tkalender.bilder.length > 0">
              <CRow>
                <CCol sm="2"></CCol>
                <CCol sm="2" v-for="(bild, index) in tkalender.bilder" v-bind:key="'bild' + index">
                  <Bild :bild="bild" type="tageskalender"/>
                </CCol>
              </CRow>
            </CCol>
          </CRow>

          <CRow v-for="(abwesenheit, index) in tagesansicht.abwesenheiten" v-bind:key="'abw' + index" class="tkurlaubentry">
            <CCol sm="3" class="font-weight-bold">{{abwesenheit.art}}</CCol>
            <CCol sm="6">{{abwesenheit.name}}</CCol>
            <CCol sm="1"></CCol>
            <CCol sm="2" class="text-right font-weight-bold">{{abwesenheit.dauer|number('0.00')}} Std.</CCol>
          </CRow>

          <CRow v-for="(freizeit, index) in tagesansicht.freizeiten" v-bind:key="'frz' + index" class="tkfreizeitentry">
            <CCol sm="3" class="font-weight-bold">Allg. Freizeit / Fortbildung</CCol>
            <CCol sm="6">{{freizeit.name}}</CCol>
            <CCol sm="1"></CCol>
            <CCol sm="2" class="text-right font-weight-bold">{{freizeit.dauer|number('0.00')}} Std.</CCol>
          </CRow>

          <CRow class="mt-4">
            <CCol sm="12" class="text-right font-weight-bold">
              <span>Summe der Stunden:</span>
              &nbsp;
              <span v-if="tagesansicht.summe">{{tagesansicht.summe|number('0.00')}} Std.</span>
              <span v-else>- Std.</span>
            </CCol>
          </CRow>

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import Bild from '@/components/Bild'

export default {
  name: 'tageskalender-summe',
  components: {
    Bild
  },
  props: {
    datum: {
      default: '',
      type: [Date, String]
    }
  },
  created () {
    this.$eventBus.$on('reloadtageskalender', (data) => {
      this.convertProps()
      this.loadData()
    })
  },
  mounted () {
    this.convertProps()
    this.loadData()
  },
  data () {
    return {
      tag: '',
      localDate: '',
      tagesansicht: []
    }
  },
  computed: {
    user () {
      return this.$store.getters['security/user']
    },
    trickVue () {
      // TODO: this nicht vorhanden in gekapselten v-for ???
      return this
    }
  },
  watch: {
    datum: function () {
      this.convertProps()
      this.loadData()
    }
  },
  methods: {
    getFormatedDate (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    getSumArbeitszeiten (array, tag) {
      this.tag = tag
      const result = array.reduce((part, value) => {
        if (this.user.userid === value.mitarbeiter.mitarbeiterid && this.getFormatedDate(this.tag) === this.getFormatedDate(value.datum)) {
          return parseFloat(part) + parseFloat(value.dauer)
        }
        return parseFloat(part)
      }, 0)
      this.tag = null
      return result
    },
    convertProps: function () {
      if (this.datum === '') {
        this.localDate = moment().format('YYYY-MM-DD')
      }
      if (this.datum instanceof Date) {
        this.localDate = moment(this.datum).format('YYYY-MM-DD')
      }
    },
    loadData: function () {
      if (this.localDate !== '') {
        Vue.axios.get('/tageskalender/tagesansicht/' + this.localDate)
          .then((response) => {
            this.tagesansicht = response.data
          })
      }
    },
    print: function (protokollid) {
      const routeData = this.$router.resolve({ path: `/print/${protokollid}` })
      window.open(routeData.href, '_blank')
    },
    tkDelete: function (tageskalenderid) {
      const self = this
      const options = {
        okText: 'Ja',
        cancelText: 'Nein'
      }
      this.$dialog
        .confirm('Den Eintrag wirklich löschen?', options)
        .then((dialog) => {
          Vue.axios.delete('/tageskalender/delete/' + tageskalenderid)
            .then((response) => {
              self.$snotify.error('Der Tageskalender-Eintrag wurde gelöscht, die Grunddaten werden neu ermittelt.', {
                position: 'rightTop',
                timeout: 4000
              })
              self.$eventBus.$emit('reloadtageskalender', [])
            })
        })
    },
    tkCopy: function (tageskalenderid) {
      this.$router.push({ path: `/tageskalender/copy/${tageskalenderid}` })
    }
  }
}
</script>
