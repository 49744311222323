<template>
  <div class="protokoll-projekt">

    <h5 class="font-weight-bold" style="font-size: 1rem;">Projekt / Baustelle</h5>
    <CInput
      rows="5"
      disabled="disabled"
      v-model="projektDetails"
    >
      <template #append>

        <CButton color="light" v-on:click="openModal()" v-if="kunde">
          <CIcon name="cil-building" />
        </CButton>
      </template>
    </CInput>

    <CModal
      title='Projekt / Baustelle'
      color="success"
      size="lg"
      :show.sync="projektModal"
    >
      <div v-if="viewMode=='search'">
        <div class="button-container d-flex justify-content-end mb-2">
          <CButton @click="viewMode = 'new'" v-if="viewMode != 'new'" color="info" class="">Neu</CButton>
        </div>
        <ProjektAuswahl
          ref="projektauswahl"
          v-bind:validate="false"
          formLabelCLass="d-none"
          v-model="projekt"
          :kunde="kunde"
        />
        <CInput disabled="disabled" v-model="projektObjekt.name" v-if="projektObjekt"/>
        <CTextarea
          rows="5"
          disabled="disabled"
          v-model="projektAdresse"
          v-if="projektObjekt"
        />
      </div>
      <div v-if="viewMode=='new'">
        <NeuesProjekt v-model="neuesProjekt" :uuid="kunde"/>
      </div>

      <template #header>
        <h6 class="modal-title">Projekt / Baustelle</h6>
        <CButtonClose @click="projektModal = false"/>
      </template>
      <template #footer>
        <div>
          <CButton @click="viewMode = 'search'" v-if="viewMode != 'search'" color="info" class="mr-2">Suche</CButton>
        </div>
        <div>
          <CButton @click="closeModal()" color="danger" class="mr-2">Abbrechen</CButton>
          <CButton v-if="viewMode == 'search'" v-on:click="projektSelect" color="success">Übernehmen</CButton>
        </div>
      </template>
    </CModal>

  </div>
</template>

<script>
import Vue from 'vue'
import ProjektAuswahl from '@/components/ProjektAuswahl'
import NeuesProjekt from '@/components/NeuesProjekt'

export default {
  name: 'ProtokollFirma',
  components: {
    ProjektAuswahl,
    NeuesProjekt
  },
  props: {
    kunde: [Number, String]
  },
  data () {
    return {
      projektModal: false,
      projektObjekt: null,
      projekt: null,
      projektAusgewaehlt: null,
      viewMode: 'search',
      neuesProjekt: null
    }
  },
  computed: {
    projektAdresse: function () {
      let result = ''
      const projektdetails = this.projektObjekt
      if (projektdetails) {
        result = projektdetails.strasse + ' ' + projektdetails.hausnr + '\n'
        result = result + projektdetails.plz + ' ' + projektdetails.ort
      }
      return result
    },
    projektDetails: function () {
      let result = ''
      const projektdetails = this.projektObjekt
      if (projektdetails) {
        result = projektdetails.name
      }
      return result
    }
  },
  watch: {
    neuesProjekt: function () {
      if (this.neuesProjekt) {
        this.projektAusgewaehlt = this.neuesProjekt
        this.projekt = this.neuesProjekt
        this.closeModal()
      }
    },
    projekt: function () {
      if (this.projekt) {
        Vue.axios.get('/projekte/details/' + this.projekt)
          .then((response) => {
            this.projektObjekt = response.data
          })
      } else {
        this.projektObjekt = null
      }
      this.$emit('input', this.projekt)
    },
    kunde: function () {
      this.projekt = null
    },
    viewMode: function () {
      if (this.viewMode === 'search') {
        this.$nextTick(function () {
          if (this.$refs.projektauswahl) {
            this.$refs.projektauswahl.$refs.projektselect.$refs.search.focus()
          }
        })
      }
    }
  },
  methods: {
    openModal: function () {
      this.projektModal = true
      this.$nextTick(function () {
        if (this.$refs.projektauswahl) {
          this.$refs.projektauswahl.$refs.projektselect.$refs.search.focus()
        }
      })
    },
    closeModal: function () {
      this.projektModal = false
      this.viewMode = 'search'
    },
    projektSelect: function () {
      this.projektAusgewaehlt = this.projekt
      this.closeModal()
    }
  }
}
</script>
