<template>
  <ValidationObserver v-slot="{ invalid }">
    <CForm class="no-mb" @submit.prevent="newKunde()">

      <KundenText v-model="kdnr" formLabel="Kundennummer" rules=""/>
      <KundenText v-model="name" formLabel="Name" />
      <KundenText v-model="name2" formLabel="Name 2" rules=""/>
      <KundenText v-model="zusatz" formLabel="Zusatz" rules=""/>
      <KundenText v-model="strasse" formLabel="Strasse" rules=""/>
      <KundenText v-model="hausnr" formLabel="Hausnummer" rules=""/>
      <KundenText v-model="plz" formLabel="Postleitzahl" rules=""/>
      <KundenText v-model="ort" formLabel="Ort" rules=""/>
      <KundenText v-model="ansprechpartner" formLabel="Ansprechpartner" rules=""/>
      <KundenText v-model="telefon" formLabel="Telefon" rules=""/>
      <KundenTextArea v-model="bemerkung" formLabel="Bemerkung" rules=""/>

      <FormError v-if="formError" :errormessage="formError"/>

      <CRow class="mt-4">
        <CCol col="12" class="text-right">
          <CButton color="primary" class="px-4" :disabled="invalid" type="submit">Kunde anlegen</CButton>
        </CCol>
      </CRow>

    </CForm>
  </ValidationObserver>
</template>

<script>
import Vue from 'vue'
import FormError from '@/components/FormError'
import KundenText from '@/components/FormElements/Text'
import KundenTextArea from '@/components/FormElements/TextArea'

export default {
  name: 'NeuerKunde',
  components: {
    FormError,
    KundenText,
    KundenTextArea
  },
  data () {
    return {
      kdnr: '',
      name: '',
      name2: '',
      zusatz: '',
      strasse: '',
      hausnr: '',
      plz: '',
      ort: '',
      ansprechpartner: '',
      telefon: '',
      bemerkung: '',
      formError: null
    }
  },
  props: {
    value: [Number, String]
  },
  mounted () {
    this.setDefaults()
  },
  methods: {
    setDefaults () {
      this.kdnr = ''
      this.name = ''
      this.name2 = ''
      this.zusatz = ''
      this.strasse = ''
      this.hausnr = ''
      this.plz = ''
      this.ort = ''
      this.ansprechpartner = ''
      this.telefon = ''
      this.bemerkung = ''
      this.formError = null
    },
    newKunde () {
      Vue.axios.post('/kunden/post', {
        kdnr: this.kdnr,
        name: this.name,
        name2: this.name2,
        zusatz: this.zusatz,
        strasse: this.strasse,
        hausnr: this.hausnr,
        plz: this.plz,
        ort: this.ort,
        ansprechpartner: this.ansprechpartner,
        telefon: this.telefon,
        bemerkung: this.bemerkung
      }).then((response) => {
        this.$snotify.success('Der neue Kunde wurde angelegt.', {
          position: 'rightTop',
          timeout: 4000
        })
        this.setDefaults()
        this.$emit('input', response.data)
      }).catch((error) => {
        this.formError = error.response.data
      })
    }
  }
}
</script>
