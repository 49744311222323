<template>
  <CRow>
    <CCol sm="12">

      <CCard>

        <CCardHeader>
          Zusätzliche Stunden für diesen Tag, nicht Abrechnungsfähig
        </CCardHeader>

        <CCardBody>
          <CInput v-model="localDatum" readonly="readonly" label="Datum">
            <template #prepend-content><CIcon name="cil-calendar"/></template>
          </CInput>

          <CInput v-model="mitarbeiter" readonly="readonly" label="Mitarbeiter">
            <template #prepend-content><CIcon name="cil-user"/></template>
          </CInput>

          <Firma v-model="kunde" form-label-class="d-block" form-label="Kunde*"/>
          <Projekt v-model="projekt" form-label-class="d-block" form-label="Projekt bei Kunde" rules="" :kunde="kunde?kunde.kundenid:''"/>

          <ArbeitszeitNumber rules="required" v-model="arbeitszeit" form-label="Arbeitszeit" />

          <TaetigkeitTextArea v-model="taetigkeit" form-label="Tätigkeit*" rows="3"/>

          <TageskalenderBoolean v-model="zukuenftigBerechnen" form-label="Dem Kunden zukünfig berechnen?"/>

          <label>Optionale Bilder:</label>
          <CRow class="imageupload mt-2">
            <CCol sm="2"><TageskalenderImage v-model="image01" form-label-class="d-none"/></CCol>
            <CCol sm="2"><TageskalenderImage v-model="image02" form-label-class="d-none"/></CCol>
            <CCol sm="2"><TageskalenderImage v-model="image03" form-label-class="d-none"/></CCol>
            <CCol sm="2"><TageskalenderImage v-model="image04" form-label-class="d-none"/></CCol>
            <CCol sm="2"><TageskalenderImage v-model="image05" form-label-class="d-none"/></CCol>
          </CRow>

          <CButton
            :color="btnColor()"
            class="btn-protokoll-submit btn-block font-weight-bold pt-3 mt-4 pb-3"
            :disabled="canSubmit()"
            v-on:click="newEntry()"
          >
            <CIcon name="cil-library-add"/>
            &nbsp;
            Dem Tageskalender Hinzufügen
          </CButton>

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import Firma from '@/components/Firma'
import Projekt from '@/components/Projekt'
import ArbeitszeitNumber from '@/components/FormElements/Number'
import TaetigkeitTextArea from '@/components/FormElements/TextArea'
import TageskalenderBoolean from '@/components/FormElements/Boolean'
import TageskalenderImage from '@/components/FormElements/Image'

export default {
  name: 'tageskalender-formular',
  components: {
    Firma,
    Projekt,
    ArbeitszeitNumber,
    TaetigkeitTextArea,
    TageskalenderBoolean,
    TageskalenderImage
  },
  props: {
    datum: [Date, String]
  },
  data () {
    return {
      kunde: null,
      projekt: null,
      arbeitszeit: null,
      taetigkeit: '',
      zukuenftigBerechnen: true,
      image01: null,
      image02: null,
      image03: null,
      image04: null,
      image05: null,
      mitarbeiter: ''
    }
  },
  mounted () {
    this.setDefaults()
    this.mitarbeiterName()
  },
  computed: {
    user () {
      return this.$store.getters['security/user']
    },
    localDatum () {
      return moment(this.datum).format('DD.MM.YYYY')
    }
  },
  watch: {
  },
  methods: {
    async mitarbeiterName () {
      const mitarbeiterloaded = await this.loadMitarbeiter()
      const curUser = mitarbeiterloaded.data.filter(mitarbeiter => {
        return mitarbeiter.mitarbeiterid === this.user.userid
      })
      this.mitarbeiter = curUser[0].name
    },
    async loadMitarbeiter () {
      return Vue.axios.get('/mitarbeiter/get')
    },
    btnColor: function () {
      if (!this.canSubmit()) {
        return 'success'
      } else {
        return 'danger'
      }
    },
    canSubmit: function () {
      let disabled = false
      if (this.arbeitszeit === '' || this.arbeitszeit === null || this.arbeitszeit === 0 || this.arbeitszeit === '0.00') {
        disabled = true
      }

      return disabled
    },
    setDefaults: function () {
      this.projekt = null
      this.arbeitszeit = null
      this.taetigkeit = ''
      this.zukuenftigBerechnen = true
      this.image01 = null
      this.image02 = null
      this.image03 = null
      this.image04 = null
      this.image05 = null
      this.$forceUpdate()
    },
    newEntry: function () {
      // An diesem Punkt sollte das Formular validiert und fertig sein
      Vue.axios.post('/tageskalender/post', {
        datum: this.localDatum,
        mitarbeiter: this.user.userid,
        kunde: this.kunde.kundenid,
        projekt: this.projekt,
        dauer: this.arbeitszeit,
        taetigkeit: this.taetigkeit,
        zukuenftigBerechnen: this.zukuenftigBerechnen
      }).then((response) => {
        // Bilder speichern
        this.saveImage(response.data.tageskalenderid, 'bild01', this.image01)
        this.saveImage(response.data.tageskalenderid, 'bild02', this.image02)
        this.saveImage(response.data.tageskalenderid, 'bild03', this.image03)
        this.saveImage(response.data.tageskalenderid, 'bild04', this.image04)
        this.saveImage(response.data.tageskalenderid, 'bild05', this.image05)
        // Defaults setzen und Redirect
        this.setDefaults()
        this.$snotify.success('Der Eintrag wurde angelegt, die Grunddaten werden neu ermittelt.', {
          position: 'rightTop',
          timeout: 4000
        })
        this.$eventBus.$emit('reloadtageskalender', [])
      })
    },
    saveImage (arbeitszeitid, selektor, image) {
      if (image) {
        const formData = new FormData()
        formData.append('selektor', selektor)
        formData.append('dbid', arbeitszeitid)
        formData.append('target', 'tageskalender')
        formData.append('uploadfile', image)

        Vue.axios.post(
          '/bilder/upload',
          formData,
          {
            headers: {
              'content-type': `multipart/form-data; boundary=${formData._boundary}`
            }
          }
        )
      }
    }
  }
}
</script>
