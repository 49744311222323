<template>
  <CRow>
    <CCol sm="12" class="mx-0" id="wochenuebersichtpanel">
      <CCard>
        <CCardBody class="">
          <CRow class="flex ">
            <CCol class="d-none d-md-block justify-content-center center text-center offset-md-2" sm="12" md="1">
              <CButton
                color="info"
                square
                size="lg"
                class="arrow-nav text-center"
                :disabled="isMaxWeeks()"
                v-on:click="changeWeek(-7)"
              >
                <CIcon name="cil-arrow-thick-left" size="3xl"/>
              </CButton>
            </CCol>
            <CCol sm="12" md="6" class="justify-content-center text-center center px-1">
              <CRow class="justify-content-center text-center center">
                <CCol v-for="(day, index) in this.wholeWeek" v-bind:key="index" sm="1" md="1" class="my-2 mx-1 px-1 flex-nowrap tage">
                  <CButton
                    color="success"
                    square
                    size="sm"
                    :disabled="checkDisabled(day)"
                    :class="'dayofweek d-block' + checkToday(day)"
                    v-on:click="changeDatum(day)"
                    class="tag"
                  >
                    <span class="wochentag">{{dayName(day)}}</span>
                    <span class="datum">{{day|moment('DD.MM.')}}</span>
                  </CButton>
                </CCol>
              </CRow>
            </CCol>
            <CCol sm="12" md="1" class="d-none d-md-block justify-content-center text-center center">
              <CButton
                color="info"
                square
                size="lg"
                class="arrow-nav"
                :disabled="isActiveWeek()"
                v-on:click="changeWeek(+7)"
              >
                <CIcon name="cil-arrow-thick-right" size="3xl"/>
              </CButton>
            </CCol>
            <CCol class="mt-2 justify-content-center text-center center" md="2" sm="12">
              <CRow  class="justify-content-center text-center center">
                <CCol class="d-md-none" sm="1" md="2">
                  <CButton
                    color="info"
                    square
                    size="lg"
                    class="arrow-nav text-center"
                    :disabled="isMaxWeeks()"
                    v-on:click="changeWeek(-7)"
                  >
                    <CIcon name="cil-arrow-thick-left" size="3xl"/>
                  </CButton>
                </CCol>
                <CCol class="" md="2" sm="1">
                  <CButtonGroup class="" :vertical="false">
                    <CButton
                      color="success"
                      square
                      size="lg"
                      v-on:click="switchToToday()"
                    >
                      <CIcon name="cil-calendar" size="xl"/>
                    </CButton>
                    <CButton
                      color="warning"
                      square
                      size="lg"
                      class="ml-4"
                      v-on:click="printWeek()"
                    >
                      <CIcon name="cil-print" size="xl"/>
                    </CButton>
                  </CButtonGroup>
                </CCol>
                <CCol sm="1" md="2" class="d-md-2 d-md-none ">
                  <CButton
                    color="info"
                    square
                    size="lg"
                    class="arrow-nav"
                    :disabled="isActiveWeek()"
                    v-on:click="changeWeek(+7)"
                  >
                    <CIcon name="cil-arrow-thick-right" size="3xl"/>
                  </CButton>
                </CCol>
              </CRow>
            </CCol>
          </CRow>

        </CCardBody>
      </CCard>

    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'

export default {
  name: 'tageskalender-kalenderwoche',
  props: {
    value: [String, Date]
  },
  data () {
    return {
      datum: '',
      rueckwirkend: 0,
      position: 1
    }
  },
  mounted () {
    this.datum = this.value
    this.loadRueckwirkend()
  },
  watch: {
    value: function () {
      this.datum = this.value
    },
    datum: function () {
      this.$emit('input', this.datum)
    }
  },
  computed: {
    user () {
      return this.$store.getters['security/user']
    },
    weekStartsWith () {
      let result = null
      if (this.datum) {
        const today = new Date(this.datum)
        const day = today.getDay()
        if (day !== 1) {
          today.setHours(-24 * (day - 1))
          today.setHours(0, 0, 0, 0)
        }
        result = today
      }
      return result
    },
    weekEndsWith () {
      let result = null
      if (this.datum) {
        const date = new Date(this.datum)
        const today = date.getDate()
        const day = date.getDay()
        const nextSunday = date.setDate(today - day + 7)
        result = new Date(nextSunday).setHours(23, 59, 59, 59)
      }
      return result
    },
    wholeWeek () {
      const daysOfWeek = []
      for (let d = this.weekStartsWith; d < this.weekEndsWith; d.setDate(d.getDate() + 1)) {
        daysOfWeek.push(new Date(d))
      }
      return daysOfWeek
    }
  },
  methods: {
    loadRueckwirkend () {
      const userid = this.user.userid
      if (userid) {
        Vue.axios.get('/mitarbeiter/get/user/' + userid)
          .then((response) => {
            let r = response.data
            if (r.length > 0) {
              r = r[0]
            }
            if (r.mitarbeiterid === userid) {
              if (r.tageskalender_rueckwirkend) {
                this.rueckwirkend = r.tageskalender_rueckwirkend
              }
            }
          })
      }
    },
    dayName (datum) {
      const day = datum.getDay()
      const dayNames = []
      dayNames[1] = 'MO'
      dayNames[2] = 'DI'
      dayNames[3] = 'MI'
      dayNames[4] = 'DO'
      dayNames[5] = 'FR'
      dayNames[6] = 'SA'
      dayNames[0] = 'SO'
      return dayNames[day]
    },
    checkDisabled (checkDate) {
      const testAgainst = new Date(checkDate)
      testAgainst.setHours(0, 0, 0, 0)
      return testAgainst > new Date().setHours(0, 0, 0, 0)
    },
    checkToday (checkDate) {
      const testAgainst = new Date(checkDate).setHours(0, 0, 0, 0)
      const chosenDate = new Date(this.datum).setHours(0, 0, 0, 0)
      if (testAgainst === chosenDate) {
        return ' active'
      } else {
        return ''
      }
    },
    changeDatum (newDatum) {
      this.datum = new Date(newDatum)
    },
    isActiveWeek () {
      if (this.position === 1) {
        return true
      } else {
        return false
      }
    },
    isMaxWeeks () {
      if (this.position >= this.rueckwirkend) {
        return true
      } else {
        return false
      }
    },
    switchToToday () {
      const today = new Date().setHours(0, 0, 0, 0)
      this.datum = new Date(today)
    },
    changeWeek (direction) {
      const result = new Date(this.datum)
      if (direction < 0) {
        this.position = this.position + 1
        result.setDate(result.getDate() - 7)
        this.datum = result
      } else {
        this.position = this.position - 1
        result.setDate(result.getDate() + 7)
        this.datum = result
      }
    },
    printWeek () {
      const week = moment(this.datum).week()
      const year = moment(this.datum).year()

      const routeData = this.$router.resolve({ path: `/tageskalender/print/${year}/${week}` })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
